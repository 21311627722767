# console.log('Spacestor intranet!')

# hideable products elements
$(document).ready ->
  $('.inner-wrapper').hide()
  $('.inner-wrapper:first').show().toggleClass('open')
  $('.inner-wrapper:first').siblings('h2').find('.product-hider').toggleClass('active-arrow')
  $('.design-options__list__item__name').on('click', ->
    $('.design-options__list__item').removeClass('active')
    $(this).parent().addClass('active')
    contentHeight = $(this).siblings('.design-options__list__item__boxes').height()
    $(this).parents('.design-options__list').css('height', contentHeight)
  )
  $('.footer__sitemap').on('click', ->
    $('.footer-sitemap-container').toggleClass('active')
    if $('.footer-sitemap-container').hasClass('active')
        $("html, body").animate({ scrollTop: $(document).height() }, 1000)
  )

  $('.question__answer input').each(() ->
    span = document.createElement('span')
    span.classList.add('checkmark')
    $(this).parent()[0].appendChild(span)
  )
  $('#voice_next').on('click', (e) ->
    e.preventDefault()
    if $('audio').length > 0
        $('#voice_answer').addClass('hidden')
        if $('#open_answer').length
            $('#open_answer').removeClass('hidden')
            $('#open_next').removeClass('hidden')
        else
            $('#closed_answer').removeClass('hidden')
            $('input[name=next').removeClass('hidden')
  )

  $('#open_next').on('click', (e) ->
      e.preventDefault()
      is_valid = $('#id_open_answer').valid()
      if is_valid
        $('#open_answer').addClass('hidden')
        $('#open_next').addClass('hidden')
        $('#closed_answer').removeClass('hidden')
        $('input[name=next]').removeClass('hidden')
  )
  $('input[name=next').on('click', (e) ->
      e.preventDefault()
      formData = new FormData($('#questionForm')[0])
      if window.voice_data
        formData.append('voice_answer', window.voice_data, window.voice_name)
      $.ajax({
          url: window.location,
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          success: (data, status, jqXHR) ->
              url = data['url']
              window.voice_data = null
              window.location.replace(url)
          error: (data) ->
              console.log('error')
      })
  )


  if window.location.hash.startsWith('#show-replies')
      selector = 'a[href^="' + window.location.hash + '"]'
      if $(selector).length
        $(selector).trigger 'click'
        $(document).scrollTop $(selector).offset().top
  $('.product-hider').on 'click', ->
    $(this).parent().next().slideToggle()
    $(this).toggleClass 'active-arrow'
    return
  $('#profileimg').on('click', ->
    $('#id_image').click();
  )
  $('#id_image').on('change', ->
      $("#filename").text($(this).val())
  )
  return
